import { namespace, WixStoresWixCodeSdkWixCodeApi } from '..'
import { PlatformUtils } from '@wix/thunderbolt-symbols'
import { CART_API_BASE_URL, CATALOG_API_BASE_URL, STORES_APP_DEF_ID } from './constants'
import {
	loadAmbassadorWixEcomCartServicesWebHttp,
	loadAmbassadorWixEcomCatalogWebHttp,
	loadCartMapper,
	loadProductOptionsAvailabilityMapper,
	loadProductVariantsMapper,
} from './dynamicImports'

export function WixStoresSdkFactory(
	_: any,
	__: any,
	{ sessionServiceApi }: PlatformUtils
): { [namespace]: WixStoresWixCodeSdkWixCodeApi } {
	const getInstanceFunc = () => sessionServiceApi.getInstance(STORES_APP_DEF_ID)

	const getRequestHeaders = () => ({
		Authorization: getInstanceFunc(),
		Accept: 'application/json',
	})

	return {
		[namespace]: {
			async getProductOptionsAvailability(
				productId: string,
				options: { [key: string]: string } = {}
			): Promise<any> {
				const { WixEcommerceCatalogWeb } = await loadAmbassadorWixEcomCatalogWebHttp()
				const { productOptionsAvailabilityMapper } = await loadProductOptionsAvailabilityMapper()

				const catalogApiFactory = WixEcommerceCatalogWeb(CATALOG_API_BASE_URL).CatalogReadApi()
				const catalogApi = catalogApiFactory(getRequestHeaders())

				const res = await catalogApi.productOptionsAvailability({ id: productId, options })
				return productOptionsAvailabilityMapper(res as any)
			},
			async getProductVariants(productId: string, options: { [key: string]: string } = {}): Promise<any> {
				const { WixEcommerceCatalogWeb } = await loadAmbassadorWixEcomCatalogWebHttp()
				const { productVariantsParamMapper, productVariantsMapper } = await loadProductVariantsMapper()

				const catalogApiFactory = WixEcommerceCatalogWeb(CATALOG_API_BASE_URL).CatalogReadApi()
				const catalogApi = catalogApiFactory(getRequestHeaders())

				const res = await catalogApi.queryVariants({ id: productId, ...productVariantsParamMapper(options) })
				return productVariantsMapper(res as any)
			},
			async getCurrentCart(): Promise<any> {
				const { WixEcommerceCartServicesWeb } = await loadAmbassadorWixEcomCartServicesWebHttp()
				const { cartMapperClient } = await loadCartMapper()

				const cartApiFactory = WixEcommerceCartServicesWeb(CART_API_BASE_URL).Carts()
				const cartApi = cartApiFactory(getRequestHeaders())

				const res = await cartApi.getCurrentCart({})
				return cartMapperClient(res.cart as any, getInstanceFunc)
			},
		},
	}
}
