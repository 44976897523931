import _ from 'lodash'
import { logSdkError } from '@wix/thunderbolt-commons'
import { error_length_accept_single_value, error_length_exceeds, error_length_in_range, error_type } from './errors'

const TYPES = {
	NUMBER: 'number',
	STRING: 'string',
	BOOLEAN: 'boolean',
	OBJECT: 'object',
}

const functionName = 'startPayment'

// Migrate to validation utils from platform
// https://jira.wixpress.com/browse/PLAT-500
function validate({ paymentId, options }) {
	let valid = true
	const { userInfo, showThankYouPage, skipUserInfoPage, termsAndConditionsLink, allowManualPayment } = options

	const typeRules = [
		{ acceptNil: false, propertyName: 'paymentId', value: paymentId, expectedType: 'string' },
		{ acceptNil: true, propertyName: 'options', value: options, expectedType: 'object' },
		{ acceptNil: true, propertyName: 'userInfo', value: userInfo, expectedType: 'object' },
		{
			acceptNil: true,
			propertyName: 'showThankYouPage',
			value: showThankYouPage,
			expectedType: 'boolean',
		},
		{
			acceptNil: true,
			propertyName: 'skipUserInfoPage',
			value: skipUserInfoPage,
			expectedType: 'boolean',
		},
		{
			acceptNil: true,
			propertyName: 'termsAndConditionsLink',
			value: termsAndConditionsLink,
			expectedType: 'string',
		},
		{
			acceptNil: true,
			propertyName: 'allowManualPayment',
			value: allowManualPayment,
			expectedType: 'boolean',
		},
	]
	typeRules.forEach(({ propertyName, value, expectedType, acceptNil }) => {
		if (!validateByType({ value, expectedType, acceptNil })) {
			valid = false
			logSdkError(error_type({ propertyName, functionName, wrongValue: value, expectedType }))
			return
		}
	})
	if (!validateLength({ propertyName: paymentId, value: paymentId, minLength: 1, maxLength: 256 })) {
		return false
	}
	return valid
}

function validateByType({ value, expectedType, acceptNil }) {
	if (_.isNil(value) && acceptNil) {
		return true
	}
	switch (expectedType) {
		case TYPES.NUMBER:
			return _.isNumber(value) && !_.isNaN(value)
		case TYPES.STRING:
			return _.isString(value)
		case TYPES.BOOLEAN:
			return _.isBoolean(value)
		case TYPES.OBJECT:
			return _.isObject(value) && !_.isArray(value) && !_.isFunction(value)
		default:
			return true
	}
}

function validateLength({ propertyName, value, minLength, maxLength }) {
	const valid = isValueInRange({
		value: _.get(value, 'length'),
		minValue: minLength,
		maxValue: maxLength,
	})
	if (!valid) {
		logSdkError(
			getLengthError({ minLength, maxLength })({
				functionName,
				propertyName,
				wrongValue: value,
				minLength,
				maxLength,
				acceptedLength: maxLength,
			})
		)
	}
	return valid
}

function getLengthError({ minLength, maxLength }) {
	let error = error_length_in_range
	if (!isActualNumber(minLength) || !isActualNumber(maxLength)) {
		return error
	}

	if (minLength === maxLength) {
		error = error_length_accept_single_value
	} else if (minLength === 0 && maxLength > 0) {
		error = error_length_exceeds
	}
	return error
}

function isValueInRange({ value, minValue, maxValue, acceptNil }) {
	if (acceptNil && _.isNil(value)) {
		return true
	}
	if (
		!isActualNumber(maxValue) ||
		!isActualNumber(value) ||
		!isActualNumber(minValue) ||
		maxValue < value ||
		value < minValue
	) {
		return false
	}
	return true
}

function isActualNumber(value) {
	return _.isNumber(value) && !_.isNaN(value)
}

export { validate }
