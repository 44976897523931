import { namespace, PaymentsWixCodeSdkFactoryData, PaymentsWixCodeSdkHandlers, PaymentsWixCodeSdkWixCodeApi } from '..'
import { getOpenModalConfig } from './openModalConfig'
import { validate } from '../validations'
import { getPaymentResults } from './getPaymentResult'
import { createBiLogger } from './bi'
import { createFedopsLogger } from './fedops'
import { PaymentOptions } from '../types'
import { PlatformUtils, PlatformEnvData } from '@wix/thunderbolt-symbols'

const consoleErrorPrefix = 'WixPay.startPayment: '

/**
 * This is SDK Factory.
 * Expose your Corvid API
 */
export function PaymentsSdkFactory(
	{ cashierCsmEntry }: PaymentsWixCodeSdkFactoryData,
	{ openModal }: PaymentsWixCodeSdkHandlers,
	{ biUtils, sessionServiceApi }: PlatformUtils,
	{ bi: biData }: PlatformEnvData
): { [namespace]: PaymentsWixCodeSdkWixCodeApi } {
	return {
		[namespace]: {
			startPayment(paymentId: string, opts: PaymentOptions) {
				const instance = sessionServiceApi.getInstance(cashierCsmEntry.appDefinitionId)
				const startTime = Date.now()
				const options = {
					showThankYouPage: true,
					skipUserInfoPage: false,
					...opts,
				}
				const biLogger = createBiLogger({
					biUtils,
					biData,
					instance,
					options,
					paymentId,
				})
				const fedopsLogger = createFedopsLogger(biUtils, biData)

				fedopsLogger.logALE()
				biLogger.logOpenModal()

				if (options.userInfo) {
					console.warn(
						`${consoleErrorPrefix}userInfo is deprecated. Pass user information to createPayment instead.`
					)
				}

				return new Promise((resolve, reject) => {
					const config = getOpenModalConfig(paymentId, instance, options, {
						startTime,
					})
					if (
						!validate({
							paymentId,
							options,
						})
					) {
						return reject(`${consoleErrorPrefix}invalid arguments`)
					}
					openModal(config.url, config.options)
						.then(() => resolve(getPaymentResults(paymentId)))
						.catch((e) => {
							biLogger.logOpenModalCompleteFailure(e, startTime)
							throw e
						})
					biLogger.logOpenModalCompleteSuccess(startTime)
				})
			},
		},
	}
}
