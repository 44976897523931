import { AppSpecData, AppParams } from '@wix/thunderbolt-symbols'
import { WixCodeAppDefId } from '../constants'
import { DynamicRouteData } from 'feature-router'
import { RouterConfig } from '@wix/thunderbolt-ssr-api'

export function createAppParams({
	appSpecData,
	wixCodeViewerAppUtils,
	routerReturnedData,
	routerConfigMap,
	appInstance
}: {
	appSpecData: AppSpecData
	wixCodeViewerAppUtils: any
	routerReturnedData: DynamicRouteData | null
	routerConfigMap: Array<RouterConfig> | null
	appInstance: string
}): AppParams {
	const createSpecificAppDataByApp: { [appDefId: string]: (appData: AppSpecData) => any } = {
		[WixCodeAppDefId]: wixCodeViewerAppUtils.createWixCodeAppData
	}

	return {
		appInstanceId: appSpecData.appDefinitionId,
		appDefinitionId: appSpecData.appDefinitionId,
		appName: appSpecData.appDefinitionName || appSpecData.type || appSpecData.appDefinitionId,
		instanceId: appSpecData.instanceId,
		instance: appInstance,
		url: appSpecData.appFields.platform.viewerScriptUrl,
		baseUrls: appSpecData.appFields.platform.baseUrls,
		appData: createSpecificAppDataByApp[appSpecData.appDefinitionId] ? createSpecificAppDataByApp[appSpecData.appDefinitionId](appSpecData) : null,
		appRouters: routerConfigMap,
		routerReturnedData: routerReturnedData?.pageData
	}
}
